<template>
  <div
    :class="[
      checked ? 'data-iterator-card--selected' : '',
      item.service_status === 'suspended'
        ? 'data-iterator-card--suspended'
        : '',
    ]"
    class="data-iterator-card"
  >
    <v-card rounded elevation="4">
      <div
        v-if="item.hasInstallStatus()"
        class="v-card__img v-card__img-installation-pending"
        :class="{
          'v-card__img-installation-pending--error': item.hasInstallFailed(),
        }"
      >
        <instance-card-img-installation-pending
          :status="item.hasInstallFailed() ? 'error' : 'in_progress'"
          :imported="item.imported"
        />
      </div>

      <div v-else>
        <div
          v-if="!item.screenshot_url"
          class="v-card__img v-card__img-not-available"
        >
          <instance-card-img-not-available :instance="item" />
        </div>
        <v-img
          v-else
          class="data-iterator-card__image"
          :src="item.screenshot_url"
          width="100%"
          height="200px"
          max-height="200px"
          position="center top"
        >
          <template v-slot:placeholder>
            <ImageLoader />
          </template>
        </v-img>
      </div>

      <div
        v-if="item.service_status === 'suspended'"
        class="instance-actions instance-actions--suspended"
        :class="unsuspendUrl() ? 'cursor-pointer' : ''"
        @click="unsuspendUrl() ? unsuspend() : undefined"
      >
        <div
          style="max-width: 120px; min-width: 120px"
          class="d-flex align-center justify-center flex-column"
        >
          <v-icon size="32" color="sand darken-1" class="mb-3">
            $suspended
          </v-icon>

          <p class="font-weight-600 p-2 sand--text text--darken-1 text-center">
            {{ $t('message.suspended') }}
          </p>
        </div>
      </div>

      <router-link
        v-else
        class="instance-actions px-12"
        :to="
          item.isActive && item.service_status == 'active'
            ? { path: `/instances/${item.id}` }
            : ''
        "
      >
        <instance-item-actions
          v-on="$listeners"
          :instance="item"
          :state="installStatus"
          :checkingWpLogin="checkingWpLogin"
          :previewNotAvailable="!item.screenshot_url"
          @openWpLogin="openWPLoginModal"
          @cancelInstallation="cancelInstall()"
          @restartInstallation="retryInstall()"
        />
      </router-link>
      <div
        v-if="!item.hasInstallStatus()"
        class="data-iterator-card__checkbox-container"
      >
        <checkbox :checked="checkedComputed" @change="handleCheckbox" />
      </div>
      <v-divider />

      <v-card-title>
        <div
          class="d-flex w-100 justify-space-between flex-nowrap align-start data-iterator-card__title-wrapper"
        >
          <v-tooltip
            v-if="hasBlockingActionInProgress"
            open-on-click
            open-on-hover
            open-delay="150"
            bottom
            transition="custom-tooltip"
            nudge-bottom="12px"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <span
                  class="h5 font-weight-bold data-iterator-card__title data-iterator-card__title--link"
                >
                  {{ item.title }}
                </span>
              </div>
            </template>
            <span>{{ $t('message.status.instance.notAvailable') }}</span>
          </v-tooltip>

          <component
            v-else
            :is="!item.hasInstallInProgress() ? 'router-link' : 'div'"
            :to="item.isActive ? `/instances/${item.id}/general` : ''"
          >
            <span
              class="h5 font-weight-bold data-iterator-card__title data-iterator-card__title--link"
              v-text="item.title"
            ></span>
          </component>

          <div v-if="!item.hasInstallInProgress()">
            <status-indicator :instance="item" />
          </div>
        </div>

        <v-tooltip
          v-if="item.hasInstallInProgress()"
          open-on-click
          open-on-hover
          open-delay="150"
          bottom
          transition="custom-tooltip"
          nudge-bottom="12px"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              class="d-flex data-iterator-card__url-box"
              v-on="on"
              v-bind="attrs"
            >
              <span class="instance-domain-link p-4" v-text="item.url"></span>
            </div>
          </template>
          {{ $t('message.status.instance.notAvailable') }}
        </v-tooltip>
        <div
          v-else-if="item.hasInstallFailed()"
          class="d-flex data-iterator-card__url-box"
        >
          <span class="instance-domain-link p-4" v-text="item.url"></span>
        </div>
        <div
          v-else
          class="d-flex data-iterator-card__url-box flex-wrap"
        >
            <div class="d-flex w-100">
              <dns-ssl-indicator
                :instance="item"
                @dns-propagation-in-progress="isDnsPropagationInProgress = true"
              />

              <a
              class="instance-domain-link p-4 ml-1"
              target="_blank"
              :href="item.url"
              v-text="item.url"
              ></a>
            </div>
        
          <preview-site-button
            v-if="$store.state.home.app.integrations.preview_site_provider_enabled && isDnsPropagationInProgress"
            :instance="item"
          />
        </div>
      </v-card-title>

      <v-card-text class="pt-3" ref="">
        <div ref="labelContainer" class="w-100">
          <instance-labels
            :disabled="hasBlockingActionInProgress ? true : false"
            :remainingAsButton="true"
            :containerClass="'ml-0'"
            :labels="item.labels"
            :maxLabelsDisplayed="99"
            :chips="true"
            xsmall
            :maxWidth="maxLabelWidth"
            :instance="item"
            :filtersActive="true"
            @toggleFilter="toggleFilter"
          />
        </div>
      </v-card-text>
      <v-card-actions class="justify-space-between pt-3">
        <template>
          <div class="d-flex">
            <instance-subinstance-indicator
              :instance="item"
              :key="`instance-${item.id}`"
              :state="installStatus"
              @cancelInstallation="cancelInstall()"
              @restartInstallation="retryInstall()"
            />
            <instance-subinstance-indicator
              v-for="subinstanceItem in item.subInstances"
              :key="`subinstance-${subinstanceItem.id}`"
              :instance="subinstanceItem"
              :state="installStatus"
              @cancelInstallation="cancelInstall()"
              @restartInstallation="retryInstall()"
            />
          </div>
        </template>
        <shares-label :instance="item" />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import InstanceLabels from "./InstanceLabels.vue";
import StatusIndicator from "./StatusIndicator.vue";
import Checkbox from "../buttons/Checkbox.vue";
import InstanceItemMenu from "./InstanceItemMenu.vue";
import DnsSslIndicator from "../security/DnsSslIndicator.vue";
import InstanceCardImgNotAvailable from "./InstanceCardImgNotAvailable.vue";
import InstanceCardImgInstallationPending from "./InstanceCardImgInstallationPending.vue";
import InstanceSubinstanceIndicator from "./InstanceSubinstanceIndicator.vue";
import InstanceItemActions from "./InstanceItemActions.vue";
import Loader from "../Loader.vue";
import DataIteratorItemMixin from "../../mixins/DataIteratorItemMixin";
import ImageLoader from "../ImageLoader.vue";

import { OpenWPLoginModalMixin } from "../../mixins/OpenModalAction";

import Api from "../../apis/Api";
import SharesLabel from "../labels/SharesLabel.vue";
import Auth from "../../apis/Auth";
import PreviewSiteButton from "../backups/PreviewSiteButton.vue";

export default {
  components: {
    PreviewSiteButton,
    InstanceLabels,
    StatusIndicator,
    Checkbox,
    InstanceItemMenu,
    DnsSslIndicator,
    InstanceCardImgNotAvailable,
    InstanceCardImgInstallationPending,
    InstanceSubinstanceIndicator,
    InstanceItemActions,
    Loader,
    SharesLabel,
    ImageLoader,
  },
  mixins: [DataIteratorItemMixin, OpenWPLoginModalMixin],
  data: function () {
    return {
      maxLabelWidth: 0,
      fetchingSslInfo: true,
      sslInfo: {},
      isDnsPropagationInProgress: false
    };
  },
  methods: {
    unsuspend() {
      // if (this.unsuspendUrl()) {
      this.$emit("unsuspend", this.unsuspendUrl());
      // }
    },
    unsuspendUrl() {
      return this.$store.state?.home?.user?.service_unsuspend_link
        ? this.$store.state.home.user.service_unsuspend_link
        : true;
    },
    toggleFilter: function (item) {
      this.$emit("toggleFilter", item);
    },
    calculateLabelContainerMaxWidth: function () {
      if (this.$refs.labelContainer) {
        return this.$refs.labelContainer.scrollWidth;
      } else {
        return 0;
      }
    },
    cancelInstall() {
      // TODO implement "cancelling/deleting instance in progress" ?
      this.item.async_status.installation = "in_progress";

      Api.delete(`/instances/${this.item.id}`, {
        data: { remove_data: true, remove_database: true },
      })
        .then(() => {
          Auth.home(true);
          this.item.async_status.installation = "finished";
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.installation.cancelled')
          });
          this.$emit("instance-deleted", this.item);
        })
        .catch((error) => {
          this.item.async_status.installation = "failed";
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        });
    },
    retryInstall() {
      this.$root.$emit("instance-retry-install", this.item);
    },
  },
  computed: {
    hasBlockingActionInProgress: function () {
      return this.item.hasInstallStatus();
    },
    hasInstallStatus: function () {
      return this.item.hasInstallStatus();
    },
    hasActionInProgress: function () {
      return this.item.hasActionInProgress();
    },
    hasInstallFailed: function () {
      return this.item.hasInstallFailed();
    },
    hasUpdateInProgress: function () {
      return this.item.hasUpdateInProgress();
    },
    subinstance: function () {
      return this.item;
    },
    installStatus() {
      return this.hasInstallStatus
        ? this.hasInstallFailed
          ? "error"
          : "in_progress"
        : false;
    },
  },
  mounted: function () {
    this.maxLabelWidth = this.calculateLabelContainerMaxWidth();
  },
};
</script>

<style lang="scss" scoped>
.data-iterator-card--suspended {
  border-top: 3px solid var(--v-sand-base);
  border-radius: 8px;
}

.v-card {
  border-radius: 8px !important;
  &__actions {
    padding: 0px 16px 10px 16px;
  }
  &__img {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-installation-pending--error {
      background: linear-gradient(
        180deg,
        #ffe8e8 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  &__text,
  &__title {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0px;
    padding: 12px 16px 0px 16px;
  }
  &__img-not-available {
    height: 200px;
  }
  &__img-tooltip {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.v-tooltip {
  cursor: pointer;
}

.v-card {
  &:hover {
    .instance-actions {
      opacity: 1;
      &__content {
        transform: translateY(0px);
      }
    }
  }
  .instance-actions {
    position: absolute;
    top: 0;
    height: 200px;
    z-index: 5;
    width: 100%;
    transition: opacity 0.2s ease;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(8px);
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    &--suspended {
      height: 100%;
      background-color: rgba(255, 255, 255, 0.95);
      backdrop-filter: blur(13.5px);
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>
